import React from 'react'

import Cube from '@src/components/Cube'

import IPhone from '@src/components/IPhone'

import ScreenMobileProducts from '@assets/images/screens_mobile_products.jpg'
import UIMobileOrderRow from '@assets/images/ui_mobile_order_row.png'
import UIMobileProductRow from '@assets/images/ui_mobile_product_row.png'

import Sphere from '@src/components/Sphere'
import { Block } from '@stage-ui/core'

import { upDownAnim1, upDownAnim2 } from '@utils/keyframes'

const ScalingSectionArt = () => {
  return (
    <>
      <Block
        shadow="xl"
        display={['block', 'none']}
        borderRadius="0.5rem"
        style={[
          {
            position: 'absolute',
            right: '-3rem',
            top: '24rem',
            opacity: 1,
            width: '21.4375rem',
            height: '5.8125rem',
            background: `url(${UIMobileOrderRow}) no-repeat`,
            backgroundSize: 'cover',
            animation: `${upDownAnim1} 8s ease-in-out infinite`,
          },
        ]}
      />
      <IPhone
        display={['flex', 'none']}
        scale={2}
        screen={ScreenMobileProducts}
        style={{
          position: 'absolute',
          right: '6rem',
          top: '-2rem',
        }}
      />
      <Block
        display={['block', 'none']}
        shadow="xl"
        borderRadius="1rem"
        style={[
          {
            position: 'absolute',
            right: '10rem',
            top: '12rem',
            backdropFilter: 'blur(8px)',
            opacity: 1,
            width: '22.8121rem',
            height: '7.25rem',
            background: `url(${UIMobileProductRow}) no-repeat`,
            backgroundSize: 'cover',
            animation: `${upDownAnim2} 8s ease-in-out infinite`,
            zIndex: 1,
          },
        ]}
      />
      <Cube
        display={['block', 'none']}
        hue={350}
        duration={20}
        style={{
          opacity: 0.9,
          position: 'absolute',
          right: '3rem',
          top: '6rem',
        }}
      />
      <Sphere
        scale={0.3}
        hue={105}
        style={{
          opacity: 0.8,
          position: 'absolute',
          right: '-2rem',
          top: '12rem',
        }}
      />
      <Sphere
        display={['block', 'none']}
        scale={0.3}
        hue={280}
        style={{
          opacity: 0.8,
          position: 'absolute',
          right: '26rem',
          top: '32rem',
        }}
      />
    </>
  )
}

export default ScalingSectionArt
