/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import IPhoneImage from '@assets/images/iphone.svg'
import { Block, Flexbox } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'

type IPhoneProps = {
  screen: string
  scale?: number
} & FlexboxTypes.Props
const IPhone = (props: IPhoneProps) => {
  const { scale = 1, screen, children, ...restProps } = props
  return (
    <Flexbox
      {...restProps}
      style={{
        width: `calc(9.375rem * ${scale})`,
        height: `calc(18.75rem * ${scale})`,
        // @ts-ignore
        ...restProps.style,
      }}
    >
      <Block
        style={{
          background: `url(${screen}) no-repeat`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          width: '100%',
          height: '97%',
          position: 'absolute',
          top: '1%',
          right: '0%',
        }}
      />
      <Block
        style={{
          background: `url(${IPhoneImage}) no-repeat`,
          backgroundSize: '100%',
          width: '100%',
          height: '100%',
        }}
      />
      {children}
    </Flexbox>
  )
}

export default IPhone
